import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CaixaPostalService } from "../../../../../service/caixa-postal/caixa-postal.service";

import Swal from "sweetalert2";
import { getSwal } from "../../../../../utils/swalObject";
import * as moment from "moment-timezone";
import { Moment } from "moment";
import { ConnectableObservable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";

declare var $;

@Component({
  selector: "app-enviar-mensagem",
  templateUrl: "enviar-mensagem.component.html",
  styleUrls: ["./enviar-mensagem.component.scss"],
})
export class EnviarMensagemComponent implements OnInit {
  @Output()
  onClosePopUp: EventEmitter<any> = new EventEmitter<any>();
  @Input() tipoComunicado: Object = {};
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  loading: boolean = false;
  enviarComunicadoForm: FormGroup;
  today: moment.Moment = moment().format("DD/MM/YYYY HH:mm:ss");
  destinatarios: any = null;
  anexos: Array<any> = [];
  exibirPesquisaDestinatarios: boolean = false;
  enviarTodos: boolean = false;
  caracteres: number = 4000;
  atingiuCaracterMinimo: boolean = false;
  mensagem: string;
  config: any;
  caracteresTextoPadrao: number = 0;
  permiteAlterarComunicado: boolean = false;
  comunicadoPadrao: any;
  permiteAnexo: boolean = false;
  desabilitarCampo: boolean = false;
  mensagemPadrao: boolean = false;
  mensagemNaoAltera: any;
  configPadrao: any;
  caracteresTexto: number = 0;
  caracterInvalido: boolean = false;
  atingiuMaximo: boolean = false;
  usuarios: Array<any> = [
    // {
    //   celular: '970412523',
    //   codigo: 38,
    //   cpfCnpj: '08959902000161',
    //   dataCriacao: '2018-08-17T15:54:07',
    //   dataInativacao: null,
    //   ddd: '11',
    //   detalhe: null,
    //   email: 'eicon@teste.com.br',
    //   imagem: null,
    //   nomeRazaoSocial: 'Eicon',
    //   situacao: 1,
    //   tipoIdentificacao: 2,
    // },
    // {
    //   celular: '970412523',
    //   codigo: 39,
    //   cpfCnpj: '08959902000161',
    //   dataCriacao: '2018-08-17T15:54:07',
    //   dataInativacao: null,
    //   ddd: '11',
    //   detalhe: null,
    //   email: 'eicon@teste.com.br',
    //   imagem: null,
    //   nomeRazaoSocial: 'Eicon',
    //   situacao: 1,
    //   tipoIdentificacao: 2,
    // },
    // {
    //   celular: '970412523',
    //   codigo: 40,
    //   cpfCnpj: '08959902000161',
    //   dataCriacao: '2018-08-17T15:54:07',
    //   dataInativacao: null,
    //   ddd: '11',
    //   detalhe: null,
    //   email: 'eicon@teste.com.br',
    //   imagem: null,
    //   nomeRazaoSocial: 'Eicon',
    //   situacao: 1,
    //   tipoIdentificacao: 2,
    // },
  ];

  labels: any;
  isAssuntoValido: any;
  isMensagemValida: any;
  ambiente: boolean;

  userInputClass: string =
    "form-control-wrapper usuarios-selecionados form-control d-flex align-items-end col-12";
  caracteresClass: string = "text-secondary";

  constructor(
    private fb: FormBuilder,
    private caixaPostalService: CaixaPostalService,
    private translate: TranslateService,
    private domSanitizer: DomSanitizer
  ) {
    this.setLabels();
    this.anexos = [];
  }

  ngOnInit() {
    this.criarFormulario();
    this.configurarComunicadoPadrao();
    this.configCaixaTexto();
    this.configCaixaTextoPadrao();
    this.validacaoUrl();
    // this.setInputUserStatus();
  }

  criarFormulario() {
    this.enviarComunicadoForm = this.fb.group({
      assunto: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(80)]),
      ],
      mensagem: [
        "",
        Validators.compose([
          Validators.required,
          //Validators.minLength(20)
        ]),
      ],
      enviarTodos: [false, Validators.required],
    });

    this.enviarComunicadoForm.get("assunto").valueChanges.subscribe((value) => {
      this.isAssuntoValido = value.trim() !== "";
    });

    this.enviarComunicadoForm
      .get("mensagem")
      .valueChanges.subscribe((value) => {
        console.log("mensagem invalida");
        this.isMensagemValida = value.trim() !== ""; // Mantido para verificar se a mensagem não é apenas espaços em branco
      });
  }

  // exibirDestinatarios(){
  //   this.enviarComunicadoForm.controls.enviarTodos.value === false ? this.exibirPesquisaDestinatarios = true : this.exibirPesquisaDestinatarios = false;
  // }

  fecharPopUp() {
    this.onClosePopUp.emit(false);
  }

  getColor(tipoComunicado) {
    if (tipoComunicado.cor !== null) {
      return tipoComunicado.cor.cor;
    } else {
      return "#a1a1a1";
    }
  }

  exibirSelecaoUsuario(usuario) {
    this.exibirPesquisaDestinatarios = !this.exibirPesquisaDestinatarios;
    this.setInputUserStatus();
  }

  validacaoUrl() {
    const urlsPermitidas = [
      'dteauditor.giss.com.br',
    ];

    const hostname = window.location.hostname.split(':')[0];
    if (urlsPermitidas.includes(hostname)) {
      this.ambiente = true;
    } else {
      this.ambiente = false;
    }
  }

  exibirUsuariosSelecionados(usuario) {
    if (this.usuarios.length !== 0) {
      let userExist: boolean = false;
      this.usuarios.forEach((element) => {
        if (usuario.cpfCnpj === element.cpfCnpj) {
          userExist = true;
        }
      });
      if (!userExist) {
        this.usuarios.push(usuario);
      }
    } else {
      this.usuarios.push(usuario);
    }
    this.destinatarios = this.usuarios;
    this.exibirPesquisaDestinatarios = false;
    this.setInputUserStatus();
  }

  setInputUserStatus() {
    this.userInputClass =
      "form-control-wrapper usuarios-selecionados form-control d-flex align-items-end col-12 " +
      (this.usuarios.length > 0
        ? "usuarios-list-sucess"
        : "usuarios-list-error");
  }

  removeUser(usuario) {
    this.usuarios = this.usuarios.filter(
      (element) => element.codigo !== usuario.codigo
    );
    this.destinatarios = this.destinatarios.filter(
      (element) => element.codigo !== usuario.codigo
    );
    this.setInputUserStatus();
  }

  reiniciarFormulario() {
    this.enviarComunicadoForm.reset();
    this.destinatarios = [];
  }

  fileChangeEvent(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      let file = {
        nomeArquivo: event.target.files[i].name,
        arquivo: null,
      };
      if (event.target.files && event.target.files[i]) {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (evt) => {
          this._handleReaderLoaded(evt, file);
        };
      }
    }
  }

  fileChangeImportListaDestinatario(event) {
    this.destinatarios = [];

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      this.loading = true;

      reader.onload = (evt) => {
        this.caixaPostalService
          .importarDestinatarios(event.target.files[0])
          .subscribe(
            (res) => {
              console.log("teste", res);
              this.loading = false;
              this.destinatarios = this.destinatarios ? this.destinatarios : [];

              res.resultado.forEach((usuario) => {
                this.usuarios.push(usuario);
                this.destinatarios.push(usuario);
              });

              this.loading = false;

              if (res.resultado.length) {
                Swal.fire(
                  getSwal(
                    "success",
                    "Sucesso!",
                    "Seu arquivo foi importado!",
                    "ok",
                    null
                  )
                ).then(() => {
                  this.enviarComunicadoForm.markAllAsTouched();
                  this.setInputUserStatus();
                });
              } else {
                Swal.fire(
                  getSwal(
                    "warning",
                    "Atenção!",
                    "Nenhum destinatário do arquivo foi localizado no cadastro!",
                    "ok",
                    null
                  )
                );
              }
              event.target.value = "";
            },
            (err) => {
              this.loading = false;
              Swal.fire(
                getSwal(
                  "error",
                  "Ops!",
                  err.error.excecao.recomendacao,
                  "ok",
                  null
                )
              );
            }
          );
      };
    }
  }

  _handleReaderLoaded(evt, file) {
    const binaryString = evt.target.result.split(",")[1];
    file.arquivo = binaryString;
    this.anexos.push(file);
  }

  removeAnexo(index: number) {
    this.anexos.splice(index, 1);
    this.fileInput.nativeElement.value = '';
  }

  isFormInValid() {
    //console.log("form: ", this.caracteresTextoPadrao)
    if (
      (this.enviarTodos === false && this.destinatarios === null) ||
      (this.enviarTodos === false && this.destinatarios.length === 0)
    ) {
      return true;
    }

    if (this.caracteresTextoPadrao < 20 || this.atingiuMaximo) {
      if (
        this.tipoComunicado["mensagemPadrao"] &&
        this.caracteresTextoPadrao == 0
      ) {
        return false;
      }

      return true;
    }

    // return (
    //   this.enviarComunicadoForm.invalid
    //   //(!this.enviarComunicadoForm.dirty || !this.enviarComunicadoForm.touched)
    // );
  }

  configurarComunicadoPadrao() {
    console.log("Comunicado ", this.tipoComunicado);

    if (this.tipoComunicado["mensagemPadrao"] == true) {
      this.mensagemPadrao = this.tipoComunicado["mensagemPadrao"];
      this.mensagem = this.tipoComunicado["comunicadoPadrao"].mensagemPadrao;
      this.mensagemNaoAltera = this.domSanitizer.bypassSecurityTrustHtml(
        this.tipoComunicado["comunicadoPadrao"].mensagemPadrao
      );
      this.enviarComunicadoForm.controls["assunto"].setValue(
        this.tipoComunicado["comunicadoPadrao"].assunto
      );
      this.permiteAlterarComunicado =
        this.tipoComunicado["permiteAlterarComunicado"];
      this.comunicadoPadrao = this.tipoComunicado["comunicadoPadrao"];
      this.permiteAnexo = this.tipoComunicado["permiteAnexo"];

      if (this.permiteAlterarComunicado == false) {
        this.enviarComunicadoForm.controls["mensagem"].clearValidators();
        this.enviarComunicadoForm.controls["mensagem"].updateValueAndValidity();
        this.enviarComunicadoForm.controls["assunto"].clearValidators();
        this.enviarComunicadoForm.controls["assunto"].updateValueAndValidity();

        this.desabilitarCampo = true;
      }
    }
  }

  onSubmit() {
    this.loading = true;
    let obj: Object = {};

    obj = this.enviarComunicadoForm.getRawValue();
    console.log(obj["mensagem"].length);
    if (obj["mensagem"].length == 0) {
      obj["mensagem"] = this.mensagem;
    }
    this.anexos.length === 0
      ? (obj["anexos"] = null)
      : (obj["anexos"] = this.anexos);
    obj["tipoComunicado"] = this.tipoComunicado;
    obj["dataEnvio"] = moment(new Date()).format("YYYY-MM-DDThh:mm:ss"); //this.today;
    if (obj["enviarTodos"] === false) {
      obj["destinatarios"] = [];
      this.destinatarios.forEach((element) => {
        obj["destinatarios"].push({ usuario: element });
      });
    } else {
      obj["destinatarios"] = null;
    }

    console.log("enviando mensagem", obj);

    this.caixaPostalService.enviarMensagem(obj).subscribe(
      (res) => {
        this.loading = false;
        this.fecharPopUp();
        // Swal.fire('Sucesso!', 'Seu comunicado foi enviado com êxito.', 'success');
        Swal.fire(
          getSwal(
            "success",
            "Sucesso!",
            "Seu comunicado foi enviado com êxito.",
            "ok",
            null
          )
        );
      },
      (err) => {
        this.loading = false;
        // Swal.fire('Ops!', err.error.excecao.recomendacao, 'error');
        Swal.fire(
          getSwal("error", "Ops!", err.error.excecao.recomendacao, "ok", null)
        );
      }
    );
  }

  enviarPorEmail() {
    this.loading = true;
    let obj: any = this.enviarComunicadoForm.getRawValue();
    console.log('let obj', obj);

    if (obj["mensagem"].length === 0) {
      obj["mensagem"] = this.mensagem;
    }

    this.anexos.length === 0
      ? (obj["anexos"] = null)
      : (obj["anexos"] = this.anexos);

    obj["tipoComunicado"] = this.tipoComunicado;
    obj["dataEnvio"] = moment(new Date()).format("YYYY-MM-DDThh:mm:ss");

    if (obj["enviarTodos"] === false) {
      obj["destinatarios"] = this.destinatarios.map(element => ({ usuario: element }));
    } else {
      obj["destinatarios"] = null;
    }

    console.log("enviando mensagem", obj);

    const destinatarios = obj["destinatarios"] ? obj["destinatarios"].map(d => d.usuario.email).join(', ') : '';
    let mensagemLimpa = obj["mensagem"].replace(/<\/?[^>]+(>|$)/g, "");
    let mensagemCompleta = obj["mensagem"];

    const mailtoLink = `mailto:${destinatarios}?subject=${encodeURIComponent(obj["assunto"])}&body=${encodeURIComponent(mensagemCompleta)}`;

    window.location.href = mailtoLink;
    this.loading = false;
  }

  setLabels() {
    this.labels = {
      adicionarDestinatarios:
        this.translate.instant("GLOBAIS.ADICIONAR") +
        " " +
        this.translate.instant("GLOBAIS.DESTINATARIOS"),
      enviarTodos: this.translate.instant("CAIXAPOSTAL.ENVIAR.ENVIATODOS"),
      sim: this.translate.instant("GLOBAIS.SIM"),
      nao: this.translate.instant("GLOBAIS.NAO"),
      anexarArquivos: this.translate.instant(
        "CAIXAPOSTAL.ARQUIVOS.ANEXARARQUIVOS"
      ),
    };
  }

  ajuda() {
    Swal.fire({
      html: 'O arquivo deve ter formato CSV, separado por ponto-e-vírgula ( ; ) informando o CPF/CNPJ e Inscrição. Exemplo: "XXX.XXX.XXX-X;YYYYYYYYYYY". O arquivo não deve conter cabeçalho. A Inscrição é opcional e quando não informada, usa-se o CNPJ raiz para importar todas as filiais.',
      confirmButtonText: "OK",
    });
  }

  configCaixaTextoPadrao() {
    const those = this;
    this.configPadrao = {
      disabledDragAndDrop: true,
      disableResizeEditor: true,
      placeholder: "Escreva aqui...",
      tabsize: 2,
      height: "200px",
      width: "100%",
      hint: {
        mentions: [
          "{id do representante} - CPF ou CNPJ formatado do representante legal",
          "{representante} - Nome ou razão social do representante legal",
          "{id do contribuinte} - CPF ou CNPJ formatado do contribuinte",
          "{contribuinte} - Nome ou razão social do contribuinte",
          "{id do procurador} - CPF ou CNPJ formatado do procurador",
          "{procurador} - Nome ou razão social do procurador",
          '{data longa} - Data do dia no formado extenso "[dia] do [mês] de [ano]"',
          "{data curta} - Data do dia no formato dd/mm/aaaa",
        ],
        match: /\B@(\w*)$/,
        search: function (keyword, callback) {
          callback(
            $.grep(this.mentions, function (item) {
              return item.indexOf(keyword) == 0;
            })
          );
        },
        content: function (item) {
          console.log("item", item);
          return "@" + item.replace(/\-[^-]+$/, "");
        },
      },
      toolbar: [
        ["style", ["bold", "italic", "underline", "clear"]],
        ["fontsize", ["fontsize"]],
        ["fontname", ["fontname"]],
        ["color", ["color"]],
        ["paragraph"],
        ["height", ["height"]],
      ],
      lang: "pt-BR",
      fontNames: [
        "Helvetica",
        "Arial",
        "Arial Black",
        "Courier New",
        "Roboto",
        "Times",
      ],
      callbacks: {
        onKeydown: function (e) {
          let caracteres = $(".note-editable").text();
          let totalCaracteres = caracteres.length;
          var t = e.currentTarget.innerText;

          if (e.keyCode === 13) {
            those.caracteresTextoPadrao++;
          }

          if (e.keyCode === 8 && totalCaracteres > 0) {
            those.caracteresTextoPadrao = totalCaracteres - 1;
          }

          if (totalCaracteres >= 1000) {
            if (
              e.keyCode != 8 &&
              !(e.keyCode >= 37 && e.keyCode <= 40) &&
              e.keyCode != 46 &&
              !(e.keyCode == 88 && e.ctrlKey) &&
              !(e.keyCode == 67 && e.ctrlKey) &&
              !(e.keyCode == 65 && e.ctrlKey)
            ) {
              e.preventDefault();
            }
          }
        },
        onChange: function (e) {
          let caracteres = $(".note-editable").text();
          let totalCaracteres = caracteres.length;
          those.caracteresTextoPadrao = totalCaracteres;
        },
        onKeyup: function (e) {
          var t = e.currentTarget.innerText;
          let totalCaracteres = t.length;
          $("#maxContentPost").text(Math.max(0, 4000 - totalCaracteres));
        },
        onPaste: function (e) {
          var t = e.currentTarget.innerText;
          let caracteres = $(".note-editable").text();
          let totalCaracteres = caracteres.length;
          var bufferText = (e.originalEvent || e).clipboardData.getData("Text");
          e.preventDefault();
          var maxPaste = bufferText.length;
          if (totalCaracteres + bufferText.length > 1000) {
            maxPaste = 1000 - t.length;
          }
          if (maxPaste > 0) {
            document.execCommand(
              "insertText",
              false,
              bufferText.substring(0, maxPaste).trim()
            );
          }
          $("#maxContentPost").text(1000 - t.length);
        },
      },
    };
    $(".note-editable").css("font-size", "16px");
    $(".note-editable").css("font-family", "Calibri");
    $(".note-current-fontsize").text("14");
    $(".note-current-fontname").text("Calibri");
    $(".note-editable").css("font-weight", "normal");
    $(".popover > .arrow").css("display", "block");
    $(".note-hint-popover .popover-content").css("width", "max-content");
    $(".note-hint-popover").css("border", "none");
    $(".note-hint-popover .popover-content").css("position", "absolute");
  }

  configCaixaTexto() {
    const those = this;
    this.config = {
      disabledDragAndDrop: true,
      disableResizeEditor: true,
      placeholder: "Escreva aqui...",
      tabsize: 2,
      height: "200px",
      width: "100%",
      hint: {
        mentions: [
          "{id do representante} - CPF ou CNPJ formatado do representante legal",
          "{representante} - Nome ou razão social do representante legal",
          "{id do contribuinte} - CPF ou CNPJ formatado do contribuinte",
          "{contribuinte} - Nome ou razão social do contribuinte",
          "{id do procurador} - CPF ou CNPJ formatado do procurador",
          "{procurador} - Nome ou razão social do procurador",
          '{data longa} - Data do dia no formado extenso "[dia] do [mês] de [ano]"',
          "{data curta} - Data do dia no formato dd/mm/aaaa",
        ],
        match: /\B@(\w*)$/,
        search: function (keyword, callback) {
          callback(
            $.grep(this.mentions, function (item) {
              return item.indexOf(keyword) == 0;
            })
          );
        },
        content: function (item) {
          console.log("item", item);
          return "@" + item.replace(/\-[^-]+$/, "");
        },
      },
      toolbar: [["style", ["bold", "italic", "underline", "clear"]]],
      lang: "pt-BR",
      fontNames: [
        "Helvetica",
        "Arial",
        "Arial Black",
        "Courier New",
        "Roboto",
        "Times",
      ],
      callbacks: {
        onKeydown: function (e) {
          let caracteres = $(".note-editable").text().trim();
          let totalCaracteres = caracteres.length;
          this.caracteresTexto = totalCaracteres;

          if (e.keyCode === 8 && totalCaracteres > 0) {
            those.caracteresTextoPadrao = totalCaracteres - 1;
          }

          if (totalCaracteres >= 4000 && !this.isMensagemValida) {
            const teclasPermitidas = [8, 37, 38, 39, 40, 46, 88, 67, 65];
            if (
              teclasPermitidas.indexOf(e.keyCode) === -1 ||
              (e.keyCode == 88 && !e.ctrlKey) ||
              (e.keyCode == 65 || e.keyCode == 67) ||
              (e.keyCode == 16) || (e.keyCode == 106) ||
              (e.keyCode == 222) || (e.keyCode == 55) || (e.keyCode == 52)
            ) {
              e.preventDefault();
              this.atingiuMaximo = true;
            } else {
              this.atingiuMaximo = false;
            }
          }
        },
        onChange: function (e) {
          let caracteres = $(".note-editable").text().trim();
          let totalCaracteres = caracteres.length;
          those.caracteresTextoPadrao = totalCaracteres;

        },
        onKeyup: function (e) {
          var t = e.currentTarget.innerText;
          let totalCaracteres = t.length;
          $("#maxContentPost").text(Math.max(0, 4000 - totalCaracteres));
        },
        onPaste: function (e) {
          var t = e.currentTarget.innerText;
          let caracteres = $(".note-editable").text();
          let totalCaracteres = caracteres.length;
          var bufferText = (e.originalEvent || e).clipboardData.getData("Text");

          e.preventDefault();
          var maxPaste = bufferText.length;
          if (totalCaracteres + bufferText.length > 4000) {
            maxPaste = 4000 - t.length;
          }
          if (maxPaste > 0) {
            document.execCommand(
              "insertText",
              false,
              bufferText.substring(0, maxPaste).trim()
            );
          }

          let novoTotalCaracteres = $(".note-editable")
            .text()
            .replace(/\s+/g, "").length;
          $("#maxContentPost").text(Math.max(0, 4000 - novoTotalCaracteres));
        },
        onInput: function (e) {
          var t = e.currentTarget.innerText;
          let totalCaracteres = t.length;
          $('#maxContentPost').text(Math.max(0, 4000 - totalCaracteres));


          if (e.key === 'Dead') {
            e.preventDefault();
            console.log('apertou')
          }
        },
      },
    };
    $(".note-editable").css("font-size", "16px");
    $(".note-editable").css("font-family", "Calibri");
    $(".note-current-fontsize").text("14");
    $(".note-current-fontname").text("Calibri");
    $(".note-editable").css("font-weight", "normal");
    $(".popover > .arrow").css("display", "block");
    $(".note-hint-popover .popover-content").css("width", "max-content");
    $(".note-hint-popover").css("border", "none");
    $(".note-hint-popover .popover-content").css("position", "absolute");
  }
}
